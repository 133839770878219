export default {
    "upload": {
        "drag_file": "Файлды жүктеу үшін осы аймаққа басыңыз немесе файлды перетасқылаңыз",
        "close": "Жабу",
        "save": "Сақтау",
        "error": "Қате",
        "max_file_size": "Файлдың максималды өлшемі {size}МБ",
        "max_file_h_w": "Суреттің минималды биіктігі мен ені {size}px",
        "upload": "Жүктеу",
    }
}
