export default {
    "invest": {
        "investProjectTitle": "Инвестициялық жоба",
        "download": "Жүктеу",
        "downloadExport": "Жүктеу",
        "roadmap": "Жол картасы",
        "addProject": "Жоба қосу",
        "noProjects": "Жобалар жоқ",
        "fileLoading": "Файлды жасау",
        "fileCreatedSuccess": "Файл сәтті жасалды",
        "fileCreationError": "Файлды жасау кезінде қате кетті",
        "downloadFile": "Жүктеу",
        "downloadRoadmap": "Жол картасы",
        "noHistory": "Тарих жоқ",
        "changeAuthor": "Өзгерістер авторы:",
        "projectInfo": "Жоба туралы ақпарат",
        "documents": "Құжаттар",
        "changeHistory": "Өзгерістер тарихы",
        "projectTasks": "Жобаның тапсырмалары",
        "createProject": "Жобаны құру",
        "createProjectConfirmation": "Инвестициялық жоба үшін тапсырмалармен жобаны құру?",
        "create": "Құру",
        "cancel": "Болдырмау",
        "projectCreated": "Жоба құрылды",
        "projectCreationError": "Жобаны құру қатесі",
        "organization": "Ұйым",
        "projectAuthor": "Жоба авторы",
        "plannedDeadline": "Жоспарланған енгізу мерзімі",
        "open": "Ашу",
        "startDate": "Басталу күні",
        "category": "Санат",
        "subcategory": "Қосалқы санат",
        "cadasterNumber": "Жер учаскесінің кадастрлық нөмірі",
        "phone": "Телефон",
        "location": "Орналасуы",
        "status": "Күйі",
        "stage": "Кезең",
        "psdDeveloped": "ЖСҚ әзірленген",
        "psdNotDeveloped": "ЖСҚ әзірленбеген",
        "status": "Күйі",
        "projectPeriod": "Жобаның мерзімі",
        "projectRegion": "Жобаның өңірі",
        "projectCost": "Жобаның құны",
        "mlnTenge": "млн. теңге",
        "mlnTenge2": "млн.тг",
        "amount": "Саны",
        "permanent": "Тұрақты",
        "temporary": "Уақытша",
        "turaqty": "Тұрақты",
        "uaqytsha": "Уақытша",
        "invest_oryndar": "орын",
        "invest_oryndar_san": "орындар",
        "invest_oryndar_plural": "орындар",
        "invest_zhinagan_karzhy": "жиналған қаржы",
        "main_info": "Жоба туралы негізгі ақпарат",
        "main_info2": "Негізгі ақпарат",
        "foreign_investor": "Шетелдік инвестор:",
        "start_date": "Жобаның басталу күні:",
        "planned_completion": "Пайдалануға беру жоспарланған мерзімі (ай, жыл):",
        "project_cost": "Жобаның құны",
        "not_specified": "Көрсетілмеген",
        "no": "Жоқ",
        "yes": "Иә",
        "psd_developed": "ПСД әзірленген",
        "psd_not_developed": "ПСД әзірленбеген",
        "company_name": "Кәсіпорын атауы",
        "company_bin": "Кәсіпорын БИН",
        "director": "Директор",
        "director_phone": "Директордың телефоны",
        "foreign_investor": "Шетелдік инвестор",
        "no": "Жоқ",
        "project_capacity": "Жобаның қуаты",
        "start_date": "Жобаның басталу күні",
        "project_stage": "Жобаның кезеңі",
        "implementation_stage": "Іске асыру кезеңі",
        "construction_stage": "Құрылыс кезеңі",
        "infrastructure": "Құрылыс инфрақұрылымы",
        "funding_sources": "Қаржыландыру көздері",
        "comment": "Пікір",
        "additional_info": "Қосымша ақпарат",
        "temporary_jobs": "Уақытша жұмыс орындары",
        "permanent_jobs": "Тұрақты жұмыс орындары",
        "land_allocated": "Жер учаскесі бөлінген",
        "yes": "Иә",
        "land_plot": "Жер учаскесі",
        "cadaster": "Кадастрлық номері ЗУ",
        "total": "Барлығы",
        "invest_statistika_alynbaidy": "Статистика алынбады",
        "filterTitle": "Статус бойынша сүзу",
        "invest_proekt_s": "жоба",
        "invest_proekta_s": "жобасы",
        "invest_proektov_s": "жобалар",
        "data_update": {
            "title": "Деректерді жаңарту"
        },
        "status": "Статус",
        "project_stage": "Жобаның кезеңі",
        "installation_stage": "СМР кезеңі, %",
        "project_capacity_placeholder": "Жобаның қуатын енгізіңіз",
        "measure_unit": "Өлшем бірлігі",
        "funding_source": "Қаржыландыру көзі",
        "invest_sobrano_sredstv": "жинақталған қаражат",
        "funding_source_placeholder": "Қаржыландыру көзін таңдаңыз",
        "funding_volume": "Қаржыландыру көлемі",
        "funding_volume_placeholder": "Қаржыландыру көлемін енгізіңіз",
        "comment": "Пікір",
        "comment_placeholder": "Пікір енгізіңіз",
        "temporary": "Уақытша",
        "temporary_jobs_placeholder": "Уақытша жұмыс орындарының санын енгізіңіз",
        "permanent": "Тұрақты",
        "permanent_jobs_placeholder": "Тұрақты жұмыс орындарының санын енгізіңіз",
        "total_project_cost": "Жобаның жалпы құны",
        "total_project_cost2": "Жобаның жалпы құны, млн. теңге",
        "add_funding_source": "Қаржыландыру көзін қосу",
        "remove": "Жою",
        "save_changes": "Өзгерістерді сақтау",
        "edit": "Өзгерту",
        "delete": "Жою",
        "source": "Қаржыландыру көзі",
        "workplaces": "Жұмыс орындары",
        "delete_project": {
            "confirm_title": "Жобаны жою \"{project_name}\"?",
            "confirm_okText": "Жою",
            "confirm_cancelText": "Бас тарту",
            "success_message": "Жоба \"{project_name}\" жойылды.",
            "error_message": "Жобаны жою кезінде қате пайда болды"
        },
        "change_status": {
            "success_message": "Статус сәтті жаңартылды",
            "error_message": "Статусты өзгерту кезінде қате пайда болды"
        },
        "form_submit": {
            "success_message": "Жоба сәтті жаңартылды",
            "error_message": "Сақтау қатесі",
            "success_created": "Жоба сәтті құрылды"
        },
        "form": {
            "project_info": "Жобаның негізгі ақпараттары",
            "step": "Қадам {step}",
            "project_name": "Жобаның атауы",
            "enter_project_name": "Жобаның атауын енгізіңіз",
            "organization": "Ұйым",
            "location": "Орын",
            "not_specified": "Көрсетілмеген",
            "region": "Область/Республикалық маңызы бар қала",
            "select_project_region": "Жобаның аймағын таңдаңыз",
            "district": "Аудан/Облыстық маңызы бар қала",
            "select_district": "Аудан/облыстық маңызы бар қаланы таңдаңыз",
            "akimat": "Акимат",
            "select_akimat": "Акиматты таңдаңыз",
            "settlement": "Есептеу",
            "select_settlement": "Есептеуді таңдаңыз",
            "village": "Ауыл",
            "select_village": "Ауыл таңдаңыз",
            "company_bin": "Кәсіпорынның БИН-і",
            "enter_company_bin": "Кәсіпорынның БИН-і",
            "company_name": "Кәсіпорынның атауы",
            "enter_company_name": "Кәсіпорынның атауы",
            "category": "Категория",
            "select_category": "Бағытын көрсетіңіз",
            "subcategory": "Подкатегория",
            "select_subcategory": "Саласын көрсетіңіз",
            "comment": "Пікір",
            "add_comment": "Пікір қосыңыз",
            "company_director_name": "Кәсіпорынның басшысы",
            "contact_phone_number": "Байланыс телефон нөмірі",
            "project_stage": "Жобаның кезеңі",
            "specify_project_stage": "Жобаның кезеңін көрсетіңіз",
            "foreign_investor": "Шетелдік инвестор",
            "specify_foreign_investor": "Шетелдік инвесторды көрсетіңіз (болған жағдайда)",
            "project_capacity": "Жобаның қуаты",
            "specify_project_capacity": "Жобаның қуатын көрсетіңіз",
            "measure_unit": "Өлшем бірлігі",
            "project_start_date": "Жобаның басталу күні",
            "planned_completion_date": "Жоспарланған пайдалануға беру мерзімі (ай, жыл)",
            "planned_completion_date_p": "Жоспарланған іске қосу күні",
            "project_cost": "Жобаның құны",
            "total_project_cost": "Жобаның жалпы құны, млн. теңге",
            "total_project_cost_p": "Жобаның барлық құны",
            "funding_source": "Қаржыландыру көзі",
            "select_funding_source": "Қаржыландыру көзін көрсетіңіз",
            "funding_volume": "Қаржыландыру көлемі, млн. теңге",
            "funding_volume2": "Қаржыландыру көлемі, млн. теңге",
            "enter_funding_volume": "Қаржыландыру көлемін көрсетіңіз",
            "add_comment_2": "Қысқа пікір қосыңыз",
            "remove": "Жою",
            "add_funding_source": "Қаржыландыру көзін қосу",
            "implementation_stage": "Іске асыру кезеңі",
            "psd": "ПСД",
            "developed": "Дайындалды",
            "not_developed": "Дайындалған жоқ",
            "construction_stage": "Құрылыс кезеңі, %",
            "construction_stage_placeholder": "Құрылыс кезеңі, %",
            "infrastructure_info": "Жүзеге асырылған инфрақұрылымдар",
            "describe_infrastructure": "Қандай инфрақұрылымдардың жүзеге асырылғанын сипаттаңыз",
            "additional_info": "Қосымша ақпарат",
            "temporary_jobs": "Уақытша жұмыс орындары",
            "enter_temporary_jobs": "Уақытша жұмыс орындарының санын көрсетіңіз",
            "permanent_jobs": "Тұрақты жұмыс орындары",
            "enter_permanent_jobs": "Тұрақты жұмыс орындарының санын көрсетіңіз",
            "land_plot_allocated": "Жер учаскесі бөлінді",
            "land_plot_area": "Жер учаскесі (гектар саны)",
            "enter_land_plot_area": "Жер учаскесін көрсетіңіз",
            "cadaster_number": "Кадастр нөмірі",
            "enter_cadaster_number": "Кадастр нөмірін көрсетіңіз",
            "project_documents": "Жоба құжаттары",
            "attach_documents": "Құжаттарды тіркеу",
            "create_project": "Жобаны құру",
            "save_invest_project": "Инвестициялық жобаны сақтау",
            "create_invest_project": "Инвестициялық жобаны құру",
            "cancel": "Бас тарту",
            "add_invest_p": "Инвестжобаны қосу",
            "edit_invest_p": "Инвестжобаны өңдеу"
        }
    }
}